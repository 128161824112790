import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import ReportModelBase from './../ReportModelBase';
import { Table } from '../../../../node_modules/semantic-ui-react';
import moment from 'moment';
import PdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import { ajaxPostDownload } from '../../../utils';

class LeaveSummaryReportModel extends ReportModelBase {

    constructor(props){
        super(props);
        this.searchUrl = "api/LeavesReport/createLeaveSummaryReport";
        this.title = "Leave Summary";
        this.exportToPdfUrl = "api/LeavesReport/exportToPdf";
        this.exportToExcelUrl = "api/LeavesReport/exportToExcel";
    }

    toTableRow = (rowData) => {
        var tableRows = [];

        rowData.LeaveBalanceModel.map((leaveDetails, index) => {
            tableRows.push(
                <Table.Row>
                    <Table.Cell>{index === 0 ? rowData.EmployeeNo : ""}</Table.Cell>
                    <Table.Cell>{index === 0 ? rowData.Employee : ""}</Table.Cell>
                    {/* <Table.Cell>{index === "0" ? (rowData.DateHired == null ? "" : moment(rowData.DateHired).format("MM/DD/YYYY")) : ""}</Table.Cell> */}
                    <Table.Cell>{leaveDetails.name}</Table.Cell>
                    <Table.Cell textAlign="right">{leaveDetails.total % 1 === 0 ? leaveDetails.total.toFixed(2) : leaveDetails.total.toString().slice(0, leaveDetails.total.toString().indexOf('.') + 3)}</Table.Cell>
                    <Table.Cell textAlign="right">{leaveDetails.carryOver % 1 === 0 ? leaveDetails.carryOver.toFixed(2) : leaveDetails.carryOver.toString().slice(0, leaveDetails.carryOver.toString().indexOf('.') + 3)}</Table.Cell>
                    <Table.Cell textAlign="right">{leaveDetails.unposted % 1 === 0 ? leaveDetails.unposted.toFixed(2) : leaveDetails.unposted.toString().slice(0, leaveDetails.unposted.toString().indexOf('.') + 3)}</Table.Cell>
                    <Table.Cell textAlign="right">{leaveDetails.used % 1 === 0 ? leaveDetails.used.toFixed(2) : leaveDetails.used.toString().slice(0, leaveDetails.used.toString().indexOf('.') + 3)}</Table.Cell>
                    <Table.Cell textAlign="right">{leaveDetails.balance % 1 === 0 ? leaveDetails.balance.toFixed(2) : leaveDetails.balance.toString().slice(0, leaveDetails.balance.toString().indexOf('.') + 3)}</Table.Cell>
                </Table.Row>
            )
        });
        return tableRows;
    }

    processPdfData = (pdfData) => {
        // pdfData.footer = function(currentPage, pageCount) {
        //     return {
        //         margin:10,
        //         columns: [
        //         {
        //             fontSize: 9,
        //             text:[{
        //                 text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
        //             }],
        //             alignment: 'center'
        //         }
        //         ]
        //     };
      
        // };
        // pdfData.footer = function(currentPage, pageCount) {
        //     return currentPage.toString() + ' of ' + pageCount; };

        const {vfs} = vfsFonts.pdfMake;
        PdfMake.vfs = vfs;
        PdfMake.createPdf(pdfData).open();
    }

    exportToExcel = (searchParameters,onFinished,onError,onFinally) => {
        var parameters = {
            asOfDate: searchParameters.asOfDate,
            EmployeeIds: searchParameters.EmployeeIds
        };
		ajaxPostDownload(
			this.exportToExcelUrl, 
			parameters, 
			"Leave Summary Report.xlsx",
			() => {
                onFinished();
			},
			(error) => {
                onError(error);
			},
			() => {
                onFinally();
			}
		)
        // var parameter = {
        //     data:searchParameters,
        //     url:this.exportToExcelUrl,
        //     onSuccess: onFinished,
        //     onError: onError,
        //     finally: onFinally,
        //     contentType: "application/vnd.ms-excel"
        // };
        // var employees = "";
        // searchParameters.employeeIds.forEach(emp => {
        //     employees += emp.toString() + ",";
        // });
        // window.location = global.ApiHost +this.exportToExcelUrl + "?" +
        // "asOfDate=" + searchParameters.asOfDate + "&" +
        // "employeeIds=" + employees;
    }

    createColumnHeaders = () => {
        return (
            <Table.Row>
                <Table.HeaderCell style={{ 'width': '11%'}}>Employee No.</Table.HeaderCell>
                <Table.HeaderCell style={{ 'width': '23%'}}>Employee Name</Table.HeaderCell>
                {/* <Table.HeaderCell style={{ 'width': '10%'}}>Date Hired</Table.HeaderCell> */}
                <Table.HeaderCell style={{ 'width': '14%'}}>Leave Type</Table.HeaderCell>
                <Table.HeaderCell style={{ 'width': '5%'}} textAlign="right">{'Grant(s)'}</Table.HeaderCell>
                <Table.HeaderCell style={{ 'width': '5%'}} textAlign="right">Previous Year Carry Over</Table.HeaderCell>
                <Table.HeaderCell style={{ 'width': '9%'}} textAlign="right">Pending File</Table.HeaderCell>
                <Table.HeaderCell style={{ 'width': '7%'}} textAlign="right">Used</Table.HeaderCell>
                <Table.HeaderCell style={{ 'width': '7%'}} textAlign="right">Balance</Table.HeaderCell>
            </Table.Row>
        );
    }

}

export default LeaveSummaryReportModel;