import React, { Component } from 'react';
import { Image } from 'semantic-ui-react';
import CutoffList from './CutoffList';
import CutoffDetail from './CutoffDetail';
import CutoffForm from './CutoffForm';
import CutoffForm2 from './CutoffForm2';
import CutoffPayrollForm from './CutoffPayrollForm';
import LeaveConversionModule from './LeaveConversion/LeaveConversionModule';
import moment from 'moment';
import { isTaskAvailable } from '../../utils';
class CutoffModule extends Component {
    state = {
        selectedEmployeeIds: [],
        mode: "search",
        searchState: {
            year: parseInt(moment().format("YYYY")),
            initialLoad: true,
            initialDTRLoad: true,
            initialActionableDTR: true,
            initialDtrSummaryLoad: true,
            initialDTRAdjustmentLoad: true,
            loading: false,
            list: [],
            cutoffType: 'STANDARD',
            page: 1,
            rowPerPage: 12,
            totalPages: 1,
            tabIndex: 0,
            selectedStatus: { label: "ALL", value: "ALL" },
            advanceFilter: {
                PayrollFrequency: "ALL",
                startMonth: "ALL",
                cutoffNumber: "ALL",
            },
            hasAdvanceFilter: false,
        },
        selected: null,
        DTRDetails: [],
        DTRSummary: [],
        DTRAdjustment: [],
        Payroll: [],
        ActionableDTRs: []
    }
    componentWillMount() {
        if (this.props.prevState != null) {
            this.state = this.props.prevState;
            var state = this.state.searchState;
            //    state.initialLoad = false;
            //    state.initialDTRLoad = false;
            this.setState({ searchState: state });

        }
    }
    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, "CutoffManagement")
    }

    detailWillUnmount = (employeeIds) => {
        this.state.selectedEmployeeIds = employeeIds;
        this.props.unmountCallback(this.state, "CutoffManagement");
    }

    onUpdateComplete = (model) => {
        var list = this.state.searchState.list;
        var searchState = this.state.searchState;

        switch (model.Type) {
            case "STANDARD":
                searchState.activeTabindex = 0;
                break;
            case "OFFCYCLE":
                searchState.activeTabindex = 1;
                break;
            case "LEAVE CONVERSION":
                searchState.activeTabindex = 2;
                break;
            case "13TH MONTH PAY":
                searchState.activeTabIndex = 3;
                break;
            case "QUIT CLAIM":
                searchState.activeTabindex = 4;
                break;
        }

        searchState.list = [model];
        searchState.selected = model;
        searchState.cutoffType = model.Type;

        // if (this.state.mode === "add" || this.state.mode === "addPayrollCutoff") {
        //     if (list.length > 0) {
        //         list.push(model);
        //         searchState.list = list;
        //     }
        //     else {
        //         searchState.list = [model];
        //     }
        //     searchState.selected = model;
        //     // searchState.page =1;
        // } else {
        //     var itemIndex = list.indexOf(this.state.selectedSchedule);
        //     if (itemIndex >= 0)
        //         list[itemIndex] = model;
        // }
        this.setState({ searchState: this.state.searchState });
        this.setState({ mode: "search" });
    }
    onUpdateCutoffs = (models) => {
        var list = this.state.searchState.list;
        var searchState = this.state.searchState;

        models.map((x) => {
            list.push(x);
        });
        searchState.list = list;
        this.setState({ searchState: this.state.searchState });
        this.setState({ mode: "search" });
    }
    onAdd = (searchState) => {
        //set blank model for schedule
        this.setState({ selected: null });
        // this.setState({searchState});
        this.setState({ mode: "add" });
    }
    onAddCutoffs = (searchstate) => {
        this.setState({ selected: null });
        this.setState({
            mode: "addCutoffs"
        });
    }
    onAddPayrollCutoff = (searchstate) => {
        this.setState({
            selected: null,
            mode: "addPayrollCutoff"
        });
    }
    onEdit = (searchState, selectedItem) => {
        this.setState({ searchState: searchState });
        this.setState({ selected: selectedItem });
        this.setState({ mode: "edit" });
    }
    onView = (searchState, selectedItem) => {
        this.setState({
            searchState: searchState,
            selected: selectedItem,
            DTRDetails: [],
            DTRSummary: [],
            DTRAdjustment: [],
            Payroll: [],
        });
        this.setState({ mode: "view" });
    }
    onUpdateState = (searchState, DTR, Summary, DTRAdjustment, Payroll) => {
        this.setState({
            searchState: searchState,
            DTRDetails: DTR,
            DTRSummary: Summary,
            DTRAdjustment: DTRAdjustment,
            Payroll: Payroll
        });
    }
    onCloseForm = (model) => {
        this.setState({ mode: "search" });
        var list = this.state.searchState.list;
        var searchstate = this.state.searchState;
        searchstate.initialDTRLoad = true;
        var dtr = this.state.DTRDetails;
        var summary = this.state.DTRSummary;
        var dtrAdjustment = this.state.DTRAdjustment;
        var payroll = this.state.Payroll;
        dtr = [];
        summary = [];
        dtrAdjustment = [];
        payroll = [];
        var itemIndex = list.indexOf(model);
        if (itemIndex >= 0)
            list[itemIndex] = model;
        this.setState({
            list,
            searchState: searchstate,
            DTRDetails: dtr,
            DTRSummary: summary,
            DTRAdjustment: dtrAdjustment,
            Payroll: payroll,
            selectedEmployeeIds: []
        });
    }
    render() {

        return this.state.mode === "search" ?
            <CutoffList
                searchState={this.state.searchState}
                onEdit={this.onEdit.bind(this)}
                onAdd={this.onAdd.bind(this)}
                onAddCutoffs={this.onAddCutoffs.bind(this)}
                onAddPayrollCutoff={this.onAddPayrollCutoff.bind(this)}
                onView={this.onView.bind(this)}
                onUpdateState={this.onUpdateState.bind(this)} />
            :
            this.state.mode === "view" ?
                (this.state.searchState.cutoffType === "LEAVE CONVERSION" ?
                    <LeaveConversionModule
                        Cutoff={this.state.selected}
                        Payroll={this.state.Payroll}
                        searchState={this.state.searchState}
                        initialLoad={this.state.searchState.initialDTRLoad}
                        onUpdateState={this.onUpdateState.bind(this)}
                        onCloseForm={this.onCloseForm.bind(this)}
                    /> :
                    <CutoffDetail
                        Cutoff={this.state.selected}
                        searchState={this.state.searchState}
                        initialLoad={this.state.searchState.initialDTRLoad}
                        DTRDetails={this.state.DTRDetails}
                        DTRSummary={this.state.DTRSummary}
                        DTRAdjustment={this.state.DTRAdjustment}
                        Payroll={this.state.Payroll}
                        onUpdateState={this.onUpdateState.bind(this)}
                        onCloseForm={this.onCloseForm.bind(this)}
                        initialEmployeeIds={this.state.selectedEmployeeIds}
                        unmountCallback={this.detailWillUnmount}
                    />
                )
                :
                this.state.mode === "addPayrollCutoff" ?
                    <CutoffPayrollForm
                        Cutoff={this.state.selected}
                        initialLoad={this.state.searchState.initialLoad}
                        onCloseForm={this.onCloseForm.bind(this)}
                        onUpdateComplete={this.onUpdateComplete.bind(this)} />
                    :
                    this.state.mode === "addCutoffs" ?
                        <CutoffForm2
                            initialLoad={this.state.searchState.initialDTRLoad}
                            onCloseForm={this.onCloseForm.bind(this)}
                            onUpdateComplete={this.onUpdateCutoffs.bind(this)} />
                        :
                        <CutoffForm
                            Cutoff={this.state.selected}
                            initialLoad={this.state.searchState.initialDTRLoad}
                            onCloseForm={this.onCloseForm.bind(this)}
                            onUpdateComplete={this.onUpdateComplete.bind(this)} />

    }
}
CutoffModule.getViewID = () => { return "CutoffManagement" };
CutoffModule.getCaption = () => { return "Cutoff Management" }
CutoffModule.getViewAccessKey = () => { return isTaskAvailable("CUTOFF_VIEW") || isTaskAvailable("CUTOFF_CREATE") || isTaskAvailable("CUTOFF_DELETE") ? true : false }
CutoffModule.getIcon = () => {
    return (<Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Cutoff.png' avatar />);
}
CutoffModule.getIconSrc = (size) => //size values: small (50x50), medium(150x150), large(300x300)
{
    return '/Icons/Cutoff.png'
}
export default CutoffModule;