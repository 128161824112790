import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Modal, Button, Input, Dropdown } from '../../../../node_modules/semantic-ui-react';
import {fnSearchEmployee } from '../../../utils';
import {fnSearchEmployeeFilter} from '../../../utils';
import SelectEmployees from '../../../Commons/SelectEmployees';

const moment = require('moment');

const separationTypeList = [
    { text: 'All', value: 'ALL'},
    { text: 'AWOL', value: 'AWOL' },
    { text: 'End of Contract', value: 'EOC' },
    { text: 'Floating', value: 'FLOATING' },
    { text: 'Resignation', value: 'RESIGNATION' },
    { text: 'Retirement', value: 'RETIREMENT' },
    { text: 'Retrenchment', value: 'RETRENCHMENT' },
    { text: 'Termination', value: 'TERMINATION' },
];

// function employeeSearchlist(list){
//     var employees = [];
//     if(list !=null){
//        list.map(x =>{
//            return(
//             employees.push({label:x.LastName + ", " + x.FirstName + " " + x.MiddleName,value:x._id})
//            )
//        })
//     }
//     return employees;
// }
class SeparatedEmployeeReportModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fromDate: moment().startOf('day').format("YYYY-MM-DD"),
            toDate: moment().startOf('day').format("YYYY-MM-DD"),
            includeDeleted:false,
            employeeIds:[],
            searchQuery:'',
            Employeelist:[],
            separationType: separationTypeList[0].value
        }
    }
    componentWillMount(){
        //fnSearchEmployee("", data => {this.setState({ Employeelist: data["content"]});},0,20, true)
    }

    // onSearch = (text) => {
    //     setTimeout
    //     this.setState({searchQuery: text })
    //     this.employeeSearch = setTimeout(this.doSearch, 400, text);
    // }

    // loadSearchEmployee = (str, source) => {
    //     if (source !== undefined && str !== source.value) {
    //         setTimeout(this.loadSearchEmployee, 200, source.value, source);
    //         return;
    //     }

    //     const { empSearchList, selected } = this.state;

    //     this.setState({ isSearchLoading: true });
    //     var ids = (selected === "" || selected === undefined) ? null : selected.toString().split(',');
    //     var selectedEmpIds = [];
    //     if (ids != null)
    //         selectedEmpIds = empSearchList.filter(x => selected.includes(x._id));

    //     fnSearchEmployeeFilter(str, data => {
    //         selectedEmpIds.forEach(x => { if (!data.includes(x["_id"])) data.push(x) })
    //         this.setState({
    //             minIndex: 0,
    //             maxIndex: 0,
    //             isSearchLoading: false,
    //             empSearchList: data,
    //         })
    //     }, 0, 20, this.state.includeInactive, ids)
    // }

    getParameters = () => {
        return {
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            message: "from separated employee modal",
            employeeIds:this.state.employeeIds,
            includeDeleted:true,
            startingIndex:0,
            itemCount:20,
            FilterDetails: "Date Range: " + ((this.state.fromDate==null ||this.state.fromDate === "") ?"": this.state.fromDate + " to ") + ((this.state.toDate==null || this.state.toDate==="")?"": this.state.toDate),
            separationType: this.state.separationType === 'ALL' ? undefined : this.state.separationType
        };
    }
    // handleSearchChange =(e,{searchQuery}) => 
    // this.setState({searchQuery:searchQuery}
    // );
    // handleComboChange = (value) => {
    //     var ids = value === "" ? null : value.split(',');
    //     this.setState({ employeeIds: ids})
    //     this.state.searchQuery = "";
    // }
    render() {
        return (
            <Modal open={this.props.open} size="tiny">
                <Modal.Header>
                    <p>Employee Separation Report Filters</p>
                </Modal.Header>
                <Modal.Content>
                    <div class="ui grid">
                        <div class="left floated left aligned eight wide column">
                            <Input type="date" label="Date From" size="mini" fluid value={this.state.fromDate} onChange={(event, data)=>{this.setState({fromDate:data.value})}} />
                        </div>
                        <div class="right floated right aligned eight wide column">
                            <Input type="date" label="Date To" size="mini" fluid value={this.state.toDate} onChange={(event, data)=>{this.setState({toDate:data.value})}} />   
                        </div>
                    </div>
                    <div class="ui grid">
                        <div class="left floated left aligned sixteen wide column">
                            <label>Type of Separation</label>
                            <Dropdown
                                fluid
                                selection
                                value={this.state.separationType}
                                options={separationTypeList}
                                onChange={(e, data) => {
                                    this.setState({ separationType: data.value });
                                }}
                            />
                        </div>
                    </div>
                    <div class="ui grid">
                        <div class="left floated left aligned sixteen wide column">
                        <label>Employee</label>
                            {/* <CustomSelect fluid placeholder='Employee.' size="mini" name="EmployeeIds"
                                onChange={this.handleComboChange.bind(this)}  
                                options={employeeSearchlist(this.state.Employeelist)}
                                onInputKeyDown={this.onSearch.bind(this)}
                                multi
                                simpleValue                                                      
                                searchQuery={this.state.searchQuery}
                                value={this.state.employeeIds}/>  */}
                            <SelectEmployees Ids={this.state.employeeIds} update={(ids)=> this.setState({employeeIds: ids})} includeDeleted={true}/>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic icon='check'content="Generate" onClick={this.props.onSave}></Button>
                    <Button basic icon='cancel' content="Cancel" onClick={this.props.onCancel}></Button>
                </Modal.Actions>
            </Modal>
        );
    }

}

export default SeparatedEmployeeReportModal;