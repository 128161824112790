import { ajaxPost } from './ajax';
import FileSaver from 'file-saver/FileSaver';
import moment from 'moment';

const minDateAsString = "1900-01-01";
const maxDateAsString = "2999-12-31";
const minDateAsDate = Date.parse(minDateAsString);
const maxDateAsDate = Date.parse(maxDateAsString);
export const empty_id = "00000000-0000-0000-0000-000000000000";
//export const empty_id = "000000000000000000000000";

export const minDate = minDateAsString;
export const maxDate = maxDateAsString;
export const momentDateFormat = "MM/DD/YYYY";

const separationTypeList = [
    { text: 'AWOL', value: 'AWOL' },
    { text: 'End of Contract', value: 'EOC' },
    { text: 'Floating', value: 'FLOATING' },
    { text: 'Resignation', value: 'RESIGNATION' },
    { text: 'Retirement', value: 'RETIREMENT' },
    { text: 'Retrenchment', value: 'RETRENCHMENT' },
    { text: 'Termination', value: 'TERMINATION' }
];

export const empState = {
    isFirstLoad: true,
    isLoading: false,
    importLoading: false,
    exportTemplateLoading: false,
    exportLoading: false,
    isSearchLoading: false,
    action: "Load",
    search: "",
    selected: [],
    selectedEmployee: [],
    selectedZKUserInfo: [],
    selectedPayrollInfo: [],
    employeeList: [],
    eligibilityList: [],
    empSearchList: [],
    empCount: 0,
    selectAll: 0,
    open: false,
    isSuperAdmin: false,
    userId: "",
    prevState: null,
    subordinateIds: [],
    listViewState: true,
    includeInactive: false,

    isPerformingAction: false,
    idPerformingAction: "",
    actionPerformed: "",

    deptSelected: "",
    deptOptions: [],

    userCanAdd: false,
    userCanEdit: false,
    userCanDelete: false,

    dtInputEmployee: "",
    reasonForLeaving: "",
    separationType: separationTypeList[0].value,
    advanceFilter: {
        employeeIds: [],
        companyIds: [],
        divisionIds: [],
        departmentIds: [],
        locationIds: [],
        positionIds: [],
        costcenterIds: [],
    },
    hasAdvanceFilter: false,

    isRestoring: false,
    isDeleting: false,
    isError: false,

    isMaximumScrollReach: false,
    isMinimumScrollReach: false,
    maxIndex: 0,
    minIndex: 0,
    isMaxEmployeeCapacity: false,
    maxEmpCount: 0,
    referer: '',
    noDataFields: []
}

export function apiValues() {
    return {
        speedFace: "Ver 2.0.36",
    };
}

export const customStyles =
{
    control: (base) => ({
        ...base,
        maxHeight: 100,
    }),
    valueContainer: (base) => ({
        ...base,
        minHeight: 36,
        maxHeight: 95,
        overflowY: 'auto'
    }),
    menu: (base) => ({
        ...base,
        zIndex: 1000,
    })
};
export const errorStyles = {
    control: (base, state) => ({
        ...base,
        color: "#DF4141",
        backgroundColor: "#FFF6F5",
        borderColor: "#ffb3b3",
        minHeight: 36,
        maxHeight: 95,
        overflowY: 'auto'
    })
}

export function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

export function transformToTitle(titleField) {
    var abbrvString = "";
    if (titleField === undefined) return abbrvString;
    if (titleField.startsWith("TIN", 0) || titleField.startsWith("SSS", 0)) {
        abbrvString = titleField.substring(0, 3) + " ";
        titleField = titleField.substring(3, titleField.length)
    }

    if (titleField.startsWith("HDMF", 0) || titleField.startsWith("HDMF", 0)) {
        abbrvString = titleField.substring(0, 4) + " ";
        titleField = titleField.substring(4, titleField.length)
    }

    titleField = titleField.split("_").join(" ");
    titleField = titleField.replace(/([a-z])([A-Z])/g, '$1 $2');
    titleField = toTitleCase(titleField);
    return abbrvString + titleField;
}

export function handleNumericOnKeyPress(e) {
    if (
        e.key.length === 1 && e.key !== '.' && isNaN(e.key) && !e.ctrlKey ||
        e.key === '.' && e.target.value.toString().indexOf('.') > -1 ||
        e.key === " "
    ) {
        e.preventDefault();
    }
}

export function handleNumericOnKeyDown(e) {
    e = e || window.event; //Get event
    if (e.ctrlKey) {
        var c = e.which || e.keyCode; //Get key code
        switch (c) {
            case 86:  //Block Ctrl+V
            case 118: //Block Ctrl+v
                // case 83: //Block Ctrl+S
                // case 87: //Block Ctrl+W --Not work in Chrome
                e.preventDefault();
                e.stopPropagation();
                break;
        }
    }
}

export function ajaxPostDownload(url, data, filename, onSuccess, onError, onEnd) {
    var ParamData =
    {
        Payload: data,
        SessionId: global.sessionId,
        SessionKey: "HRIS_SessionId",
        UpdateSession: data.updateSession == null ? true : data.updateSession,
    };
    window.fetch(
        global.ApiHost + url,
        {
            method: "POST",
            credentials: 'include',
            headers: {
                'Accept': 'application/json, xls/plain, */*',
                'Content-Type': 'application/json',
                'Content-Disposition': 'attachment'
            },
            body: JSON.stringify(ParamData)
        }
    )
        .catch(error => {
            onError(error);
        })
        .then(
            response => {
                return response.blob();
            }
        )
        .then(response => {
            FileSaver.saveAs(response, filename);
            onSuccess();
        })
        .then(
            () => {
                onEnd();
            }
        );
}

export function fullName(model) {
    return model.LastName + ", " + model.FirstName + (model.MiddleName != "" ? " " + model.MiddleName[0] + "." : "") + ((model.Suffix != "" && model.Suffix != null) ? ", " + model.Suffix : "") + " (" + model.EmployeeNo + ")";
}
export function getUnique(array, key) {
    if (typeof key !== 'function') {
      const property = key;
      key = function(item) { return item[property]; };
    }
    return Array.from(array.reduce(function(map, item) {
      const k = key(item);
      if (!map.has(k)) map.set(k, item);
      return map;
    }, new Map()).values());
  }
export function fnSearchEmployee(search, onSuccess, start, max, includeDeleted, empIDs = null, inactiveOnly) {
    var data =
    {
        Search: search,
        includeDeleted: includeDeleted,
        inactiveOnly: inactiveOnly,
        startingIndex: start != null ? start : 0,
        itemCount: max != null ? max : 50,
        employeeIds: empIDs,
        role: isTaskAvailable("VIEW_ALL_EMPLOYEES") ? "ADMIN" : "USER",
    }
    //data.Department = [];

    ajaxPost({
        url: 'api/Employee/searchWithPagination',
        data: data,
        onSuccess: resp => {
            onSuccess(resp)
        },
        finally: () => { }
    }
    )
}

export function fnAsyncSearchEmployee(search, start, max, includeDeleted, excludeEmpIds = null, includedEmpIds = null, isViewAll = false, inactiveOnly) {
    var promise = new Promise((resolve) => {
        fnSearchEmployeeFilter(search, (result) =>
        {
            resolve(result);
        }, start, max, includeDeleted, excludeEmpIds, includedEmpIds, false ,inactiveOnly);
    });
    return promise;
}

export function fnSearchEmployeeFilter(search, onSuccess, start, max, includeDeleted, excludeEmpIds = null, includedEmpIds = null, isViewAll = false, inactiveOnly) {

    var data = {
        Search: search,
        includeDeleted: includeDeleted,
        inactiveOnly: inactiveOnly, // inactiveOnly
        startingIndex: start != null ? start : 0,
        itemCount: max != null ? max : 50,
        excludedEmployeeIds: excludeEmpIds !== null && excludeEmpIds.length > 0 ? excludeEmpIds : null,
        includedEmployeeIds: includedEmpIds !== null && includedEmpIds.length > 0 ? includedEmpIds : null,
        role: isViewAll ? "ADMIN" : isTaskAvailable("VIEW_ALL_EMPLOYEES") ? "ADMIN" : "USER",
    }

    ajaxPost({
        url: 'api/Employee/searchFilter',
        data: data,
        onSuccess: resp => {
            onSuccess(resp)
        },
        onError: resp => {
            var a = resp;
        },
        finally: () => { }
    }
    )
}

export function fnGetEmployeeImagePath(employeeID, onSuccess, isAvatarImage = true) {
    var data =
    {
        EmployeeID: employeeID,
        IsAvatar: isAvatarImage
    }

    ajaxPost({
        url: 'api/Employee/getEmployeeImagePath',
        data: data,
        onSuccess: resp => {
            onSuccess(resp)
        },
        finally: () => { }
    }
    )
}

export function fnLoadZKUserInfoByEmployeeId(employeeId, onSuccess) {
    ajaxPost({
        url: 'api/ZKUserInfo/loadByEmployeeId',
        data: {
            "EmployeeId": employeeId
        },
        onError: err => {
            var z = err;
        },
        onSuccess: resp => {
            onSuccess(resp)
        },
        finally: () => { }
    }
    )
}
export function fnLoadPayrollInfoByEmployeeId(employeeId, onSuccess) {
    ajaxPost({
        url: 'api/PayrollInfo/loadByEmployeeId',
        data: {
            "EmployeeId": employeeId
        },
        onError: err => {
            var z = err;
        },
        onSuccess: resp => {
            onSuccess(resp)
        },
        finally: () => { }
    }
    )
}
export function fnLoadZKUserInfoByAccessNumber(AccessNumber, onSuccess) {
    ajaxPost({
        url: 'api/ZKUserInfo/loadByAccessNumber',
        data: {
            "AccessNumber": AccessNumber
        },
        onError: err => {
            var z = err;
        },
        onSuccess: resp => {
            onSuccess(resp)
        },
        finally: () => { }
    }
    )
}
export function fnSearchDevice(search, onSuccess, start, max, includeDeleted, selectedIds = null, excludedIds = null, statusFilter = "ALL") {
    var data = {};

    data = {
        SearchKey: search,
        IncludeDeleted: includeDeleted,
        StartIndex: start != null ? start : 0,
        MaxData: max != null ? max : 50,
        Ids: selectedIds,
        ExcludedIds: excludedIds,
        StatusFilter: statusFilter
    }
    

    ajaxPost({
        url: 'api/Device/search',
        data: data
        ,
        onError: (a) => {
            var b = a;
        },
        onSuccess: data => {
            onSuccess(data);
        },
        finally: () => { }
    })
}

export function fnCreateNotification(recipientID, requesterID, referenceID,
    notificationData, actionSelection, title, description, actionTaken, callbackFunction, onSuccess) {
    var data =
    {
        RecipientID: recipientID,
        RequesterID: requesterID,
        ReferenceID: referenceID,
        Data: notificationData,
        ActionSelection: actionSelection,
        Title: title,
        Description: description,
        ActionTaken: actionTaken,
        CallbackFunction: callbackFunction
    }

    ajaxPost({
        url: 'api/Notification/create',
        data: data,
        onError: (a) => {
            var b = a;
        },
        onSuccess: data => {
            onSuccess(data);
        },
        finally: () => { }
    })
}

export function checkDateRange(start, end) {
    var ret = {
        Message: "Success",
        Result: true,
        Title: ""
    };
    var startDate = Date.parse(start);
    var endDate = Date.parse(end);
    if (isNaN(startDate) || startDate < 0) {
        ret.Message = "Please enter a valid date. Previous valid start date is going to restore.";
        ret.Result = false;
        ret.Title = "Invalid"
        return ret;
    }
    if (isNaN(endDate) || endDate < 0) {
        ret.Message = "Please enter a valid date. Previous valid end date is going to restore.";
        ret.Result = false;
        ret.Title = "Invalid"
        return ret;
    }
    var difference = (endDate - startDate) / (86400000 * 7);
    if (difference < 0) {
        ret.Message = "The start date must come before the end date. Previous valid date range is going to restore.";
        ret.Result = false;
        ret.Title = "Invalid"
        return ret;
    }
    if (startDate < minDateAsDate) {
        ret.Message = "The start date is less than the allowable date input. Previous valid date range is going to restore.";
        ret.Result = false;
        ret.Title = "Invalid"
        return ret;
    }
    if (endDate > maxDateAsDate) {
        //ret.Message = "The end date has exceeded the allowable date input. Previous valid date range is going to restore.";
        ret.Message = "The end date is greater than the allowable date input. Previous valid date range is going to restore.";
        ret.Result = false;
        ret.Title = "Invalid"
        return ret;
    }
    return ret;
}

export function checkDateRangeNoRestore(start, end) {
    var ret = {
        Message: "Success",
        Result: true,
        Title: ""
    };
    var startDate = Date.parse(start);
    var endDate = Date.parse(end);
    if (isNaN(startDate)) {
        ret.Message = "Please enter a valid date.";
        ret.Result = false;
        ret.Title = "Invalid"
        return ret;
    }
    if (isNaN(endDate)) {
        ret.Message = "Please enter a valid date.";
        ret.Result = false;
        ret.Title = "Invalid"
        return ret;
    }
    var difference = (endDate - startDate) / (86400000 * 7);
    if (difference < 0) {
        ret.Message = "The start date must come before the end date.";
        ret.Result = false;
        ret.Title = "Invalid"
        return ret;
    }
    if (startDate < minDateAsDate) {
        ret.Message = "The start date is less than the allowable date input.";
        ret.Result = false;
        ret.Title = "Invalid"
        return ret;
    }
    if (endDate > maxDateAsDate) {
        ret.Message = "The end date is greater than the allowable date input.";
        ret.Result = false;
        ret.Title = "Invalid"
        return ret;
    }
    return ret;
}

export function checkDate(date) {
    var ret = {
        Message: "Success",
        Result: true,
        Title: ""
    };
    var dt = Date.parse(date);
    if (isNaN(dt)) {
        ret.Message = "Please enter a valid date.";
        ret.Result = false;
        ret.Title = "Invalid"
        return ret;
    }
    if (dt < minDateAsDate) {
        ret.Message = "The date is less than the allowable date input.";
        ret.Result = false;
        ret.Title = "Invalid"
        return ret;
    }
    if (dt > maxDateAsDate) {
        ret.Message = "The date is greater than the allowable date input.";
        ret.Result = false;
        ret.Title = "Invalid"
        return ret;
    }
    return ret;
}

export function checkTime(time) {
    var ret = {
        Message: "Success",
        Result: true,
        Title: ""
    };
    if (!time) {
        ret.Message = "Please enter a valid time.";
        ret.Result = false;
        ret.Title = "Invalid";
    }
    return ret;
}

export function checkYear(year, label = "Year") {
    var ret = {
        Message: "Success",
        Result: true,
        Title: ""
    };
    if (!year) {
        ret.Message = "Please enter a valid year.";
        ret.Result = false;
        ret.Title = "Invalid";
    }
    else if (Number(year) < new Date(minDateAsDate).getFullYear() || Number(year) > new Date(maxDateAsDate).getFullYear()) {
        ret.Message = label + " is out of range";
        ret.Result = false;
        ret.Title = "Invalid";
    }
    return ret;
}

export function checkInt32(number, acceptNegative = true) {
    var ret = {
        Message: "Success",
        Result: true,
        Title: ""
    };
    if (isNaN(Number(number))) {
        ret.Message = "Please enter a valid number";
        ret.Result = false;
        ret.Title = "Invalid";
    }
    else if (Number(number) > 2147483647) {
        ret.Message = "Number is out of range";
        ret.Result = false;
        ret.Title = "Invalid";
    }
    else if (Number(number) < 0 && !acceptNegative) {
        ret.Message = "Negative value is not allowed";
        ret.Result = false;
        ret.Title = "Invalid";
    }
    return ret;
}

export function fnSearchDeviceLogs(startDate, endDate, serialNumbers, empIDs, onSuccess, start, max, advanceFilter = null) {
    var data =
    {
        StartDate: startDate,
        EndDate: endDate,
        SerialNumbers: serialNumbers,
        EmployeeIds: empIDs,
        StartIndex: start != null ? start : 0,
        MaxData: max != null ? max : 20,
        AdvanceFilter: JSON.stringify(advanceFilter)
    }

    ajaxPost({
        url: 'api/DeviceLog/search',
        data: data,
        onError: (a) => {
            var b = a;
        },
        onSuccess: data => {
            onSuccess(data);
        },
        finally: () => { }
    })
}

export function isTaskAvailable(key) {
    var isAvailable = false;

    if (global.userRights != null) {
        global.userRights.forEach(item => {
            if (item.Key == key)
                isAvailable = true;
        })
    }

    return isAvailable;
}

export function loadGroups(search, groupTypes, onSuccess) {
    //  GroupTypes
    //     COMPANY
    //     DEPARTMENT
    //     DIVISION
    //     JOB
    //     CITY
    //     COST_CENTER
    //     SALARY_GRADE

    ajaxPost({
        url: "api/Group/search",
        data: {
            "Search": search,
            "includeDeleted": false,
            "inactiveOnly": false,
            "startingIndex": 0,
            "itemCount": 10,
            "Types": groupTypes
        },
        onSuccess: data => {
            onSuccess(data);
        },
        finally: () => { }
    })
}

function replaceAt(str, index, replacement) {
    var endChar = ''
    if (str.length - 1 !== index) {
        endChar = str.slice(index + 1, str.length);
    }
    return str.slice(0, index) + replacement + endChar;
}

export function isNullOrEmpty(val) {
    if (val === null || val === undefined || val === "" || val.length === 0) return true;
    else return false;
}

// export const handleNumeric = {
//     onKeyDown(e) {
//         e = e || window.event; //Get event
//         if (e.ctrlKey) {
//             var c = e.which || e.keyCode; //Get key code
//             switch (c) {
//                 case 86:  //Block Ctrl+V
//                 case 118: //Block Ctrl+v
//                     // case 83: //Block Ctrl+S
//                     // case 87: //Block Ctrl+W --Not work in Chrome
//                     e.preventDefault();
//                     e.stopPropagation();
//                     break;
//             }
//         }
//     },
//     onKeyPress(e, wholeNumberOnly = false) {
//         if (!wholeNumberOnly) {
//             if (
//                 e.key.length === 1 && e.key !== '.' && isNaN(e.key) && !e.ctrlKey ||
//                 e.key === '.' && e.target.value.toString().indexOf('.') > -1
//             ) {
//                 e.preventDefault();
//             }            
//         }
//         else {
//             if (e.key.length === 1 && isNaN(e.key) && !e.ctrlKey) {
//                 e.preventDefault();
//             }            
//         }
//     },
//     onChange(event, wholeNumberOnly = false) {
//         var numberEntry = wholeNumberOnly ? '1234567890' : '1234567890.';
//         var newValue = event.target != undefined ? event.target.value : event.value;
//         for (let index = 0; index < newValue.length; index++) {
//             if (numberEntry.includes(newValue.charAt(index)) === false)
//                 newValue = replaceAt(newValue, index, '');
//         }

//         if (newValue != null && (newValue.split(".").length - 1) > 1) {
//             newValue = newValue.slice(0, newValue.length - 1);
//         }
//         return newValue;
//     }
// };



export function handleNumeric(event, wholeNumberOnly = false) {
    var newValue = event.target != undefined ? event.target.value : event.value;

    if (newValue == '') {
        newValue = 0;
    } else {
        if (newValue.indexOf("0") == 0)
            newValue = newValue.replace('0', '');
        newValue = ValidateNumeric(newValue, wholeNumberOnly);
    }

    return newValue;
}
export function ValidateNumeric(value, wholeNumberOnly = false) {
    var numberEntry = wholeNumberOnly ? '1234567890' : '1234567890.';
    var newValue = value;

    for (let index = 0; index < newValue.length; index++) {
        if (numberEntry.includes(newValue.charAt(index)) === false)
            newValue = replaceAt(newValue, index, '');
    }

    if (newValue != null && (newValue.split(".").length - 1) > 1) {
        newValue = newValue.slice(0, newValue.length - 1);
    }

    // var current = this.state.selectedEmployee;
    // current[name] = newValue;
    return newValue == '' ? 0 : newValue;
}

export function handleIPOnKeyPress(e) {
    var chr = (e.target.value.toString() + e.key).split('.');
    if (e.key == " " || chr.length > 4 || isNaN(chr[chr.length - 1]) || Number(chr[chr.length - 1]) > 255) {
        e.preventDefault();
        return;
    }
}

export function handleBloodTypeOnKeyPress(e) {
    var regex = "^(A|B|AB|O)[+-]?$";
    var str = e.target.value + e.key;
    var valid = str.toUpperCase().match(regex) == null ? false : true;
    if (!valid || e.target.value.length > 2)
        e.preventDefault();
}

export function handleEmailOnKeyPress(e) {
    var validValues = "/[a-z]|[A-Z]|@|.|[0-9]/g";
    var str = e.target.value + e.key;
    var inputValidation = e.key.match(validValues) == null ? false : true;

    var dotValidation = str.match(/\.\./g) != null ? false : true;
    var dotAtValidation = str.match(/\.\@|\@\./g) != null ? false : true;
    var atValidation = str.match(/@/g) != null && str.match(/@/g).length > 1 ? false : true;
    if (!(atValidation && inputValidation && dotValidation && dotAtValidation))
        e.preventDefault();
}

export function dropdownMapping(list) {
    var dList = [];
    if (list != null) {
        list.map(x => {
            return (
                dList.push({ text: x.Name, value: x.id })
            )
        })
    }
    return dList;
}


export function ValidateNumber(value, wholeNumberOnly = false) {
    var numberEntry = wholeNumberOnly ? '1234567890' : '1234567890.';
    var newValue = value;

    for (let index = 0; index < value.length; index++) {
        if (numberEntry.includes(newValue.charAt(index)) === false) {
            newValue = replaceAt(newValue, index, '');
            index--;
        }
    }

    if (newValue != null && (newValue.split(".").length - 1) > 1) {
        newValue = newValue.slice(0, newValue.length - 1);
    }

    return newValue;
}

export function ValidateIPaddress(ipaddress) {
    var ret = {
        Message: "Invalid IP Address",
        Result: true,
        Title: ""
    };
    var ipformat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    if (ipaddress.match(ipformat)) {
        ret.Message = "Success";
        ret.Result = true;
    }
    return ret
}

export function TimeDisplayFormat(minutes, IsTime = false) {
    var format = global.TimeDisplayFormat;
    if (format == "HOURS")
        return MinutesToHours(minutes);
    else if (format == "HH:mm")
        return MinutesToHHMM(minutes);
    else if (format == "hh:mm tt") {
        if (IsTime) {
            return MinutesToHHmmtt(minutes);
        }
        else {
            return MinutesToHHMM(minutes);
        }

    }
    else {
        return minutes;
    }

}
export function DateDisplayFormat(date) {
    var format = global.DateDisplayFormat;
    var newDate = moment(moment(date, 'DD-MM-YYYY')).format('MM-DD-YYYY')
    return newDate;
}
export function ToRound(amount) {
    if (amount == null || amount == 0)
        return "0.00";
    return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}
export function MinutesToHours(minutes) {
    if (minutes == null || minutes == 0)
        return "";

    return (minutes / 60).toFixed(2);
}
export function MinutesToHHMM(minutes) {
    if (minutes === 0)
        return "";

    var hours = parseInt((Math.abs((minutes) / 60)));
    var minute = parseInt(minutes) % 60;
    return hours.toString().padStart(2, "0") + ":" + minute.toString().padStart(2, "0");
}
export function MinutesToHHmmtt(minutes) {
    if (minutes === 0)
        return "";

    var hours = parseInt((Math.abs((minutes) / 60)));
    var minute = parseInt(minutes) % 60;
    var isPM = hours >= 12 ? true : false;
    var tt = " AM";
    if (isPM) {
        tt = " PM";
        var t = hours - 12;
        if (t > 0)
            hours = t;
    }
    return hours.toString().padStart(2, "0") + ":" + minute.toString().padStart(2, "0") + t.toString();
}

var ONE_TO_NINETEEN = [
    "one", "two", "three", "four", "five",
    "six", "seven", "eight", "nine", "ten",
    "eleven", "twelve", "thirteen", "fourteen", "fifteen",
    "sixteen", "seventeen", "eighteen", "nineteen"
];

var TENS = [
    "ten", "twenty", "thirty", "forty", "fifty",
    "sixty", "seventy", "eighty", "ninety"
];

var SCALES = ["centavo", "", "thousand", "million", "billion", "trillion"];

// helper function for use with Array.filter
function isTruthy(item) {
    return !!item;
}

// convert a number into "chunks" of 0-999
function chunk(number) {
    var thousands = [];

    while (number > 0) {
        thousands.push(number % 1000);
        number = Math.floor(number / 1000);
    }

    return thousands;
}

// translate a number from 1-999 into English
function inEnglish(number) {
    var thousands, hundreds, tens, ones, words = [];

    if (number < 20) {
        return ONE_TO_NINETEEN[number - 1]; // may be undefined
    }

    if (number < 100) {
        ones = number % 10;
        tens = number / 10 | 0; // equivalent to Math.floor(number / 10)

        words.push(TENS[tens - 1]);
        words.push(inEnglish(ones));

        return words.filter(isTruthy).join("-");
    }

    hundreds = number / 100 | 0;
    words.push(inEnglish(hundreds));
    words.push("hundred");
    words.push(inEnglish(number % 100));

    return words.filter(isTruthy).join(" ");
}

// append the word for a scale. Made for use with Array.map
function appendScale(chunk, exp) {
    var scale;
    var and;
    if (!chunk) {
        return null;
    }

    if (exp === 0) {
        and = 'and';
    }

    scale = SCALES[exp];
    return [and, chunk, scale].filter(isTruthy).join(" ");
}

export function numToWord(num) {
    num = num.replace('.', '0');

    return chunk(num)
        .map(inEnglish)
        .map(appendScale)
        .filter(isTruthy)
        .reverse()
        .join(" ");
}

export function findDuplicates(arr) {
    var object = {};
    var result = [];

    arr.forEach(function (item) {
        if (!object[item])
            object[item] = 0;
        object[item] += 1;
    })

    for (var prop in object) {
        if (object[prop] >= 2) {
            result.push(prop);
        }
    }

    return result;
}

export function isSpeedface(value) {
    if( value == null )
    {
        return false;
    }
    return value.startsWith(apiValues().speedFace);
}

export function numberWithCommas(x){
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
