import React, { Component } from "react";
import Recaptcha from 'react-recaptcha';
import { isMobile } from 'react-device-detect';
import {
  Form,
  Header,
  Pagination,
  Button,
  Icon,
  Grid,
  Divider,
  Input,
  Loader,
  Label,
  Modal,
  Message,
  Image,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

import { ajaxPost, fetchPost } from "./ajax";


const { URL, URLSearchParams } = require("url");

var btoa = require("btoa");
var recaptchaInstance;

var info = require('./buildInfo.json');


const styles = {
  page: {
    marginLeft: "10px",
    width: "100%",
    height: "100%",
    position: "absolute"
    // background: "url('bg.png') no-repeat",
  },
  container: {
    //display: "table-row",
    verticalAlign: "middle"
  },
  textField: {
    //display: "block",
    marginTop: 9,
    width: 300
  },
  form: {
    //display: "flex",
    //height: 220,
    margin: "auto"
  },
  main: {
    position: "absolute",
    display: "table",
    width: 300,
    height: "auto",
    top: "50%",
    left: "50%",
    marginLeft: -165,
    marginTop: -200
  },
  main2: {
    position: "absolute",
    display: "table",
    width: 700,
    height: "auto",
    top: "50%",
    left: "50%",
    marginLeft: -330,
    marginTop: -200
  },
  section: {
    width: "100%",
    height: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    padding: 5
  },
  logo: {
    width: 250,
    marginTop: -10,
    marginLeft: 15
  },
  CorLogo: {
    width: '250px',
    height: '200px',
    marginTop: -10,
    marginLeft: 15
  },
  CorSeal:{
    display: 'flex',
    position: 'fixed',
    right: '3%',
    bottom: '3%',
    height: '180px',
    width: '120px',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'all 0.3s ease', // Smooth transition for the hover effect
  },
  button: {
    width: 300,
    background: "#2574bb", color: "#fff"
  },
  message: {
    marginTop: 10,
    verticalAlign: "middle",
    color: "red"
  },
  wrapper: {
    position: "relative",
    marginTop: 25,
    width: 300
  },
  recommendedBrowserMeesage: {
    position: "absolute",
    bottom: 0,
    right: 0,
    wordWrap: "break-word",
    maxWidth: 400,
    padding: "2%",
    marginRight: "10px",
    textAlign: "right",
    textJustify: "inter-word",
    color: "#808080",
    fontSize: "12px"
  }
};

class Login extends Component {
  state = {
    username: "",
    password: "",
    error: "",
    isHold: false,
    validating: false,
    txtUser: null,
    isBrowserRecommended: true,
    dbSupported: true,
    showCaptcha: false,
    showError: false,
    disableLogin: false,
    isHovered: false,
    isPopUpVisible: false,
  };

  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  handleClick = () => {
    this.setState({ isPopUpVisible: true });
  };

  handleClosePopUp = () => {
    this.setState({ isPopUpVisible: false });
  };


  handleLogin = async () => {
    var _this2 = this;

    var { username, password } = this.state;
    this.setState({ error: "" });
    if (username.trim() === "") {
      this.setState({ error: "Please input your username" });
      return;
    }
    this.setState({ validating: true });
    var resp = await fetchPost(
      "/User/login",
      {
        Pair: btoa(username + "|" + password),
        SessionKey: "HRIS_SessionId"
      });

    if( !resp.ok ){
      _this2.setState({ error: "Unable to connect to server" });
      return;
    }

    var data = await resp.json();

    if (data.isCaptcha) {
      _this2.setState({ showCaptcha: true, disableLogin: true, captchaLoading: true });
    }
    if (data.error != null) {
      _this2.setState({ error: data.error }, this.resetCaptcha.bind(this));
    }
    else if(data.isHold){
      _this2.setState({ isHold: data.isHold });
    }
    else {
      localStorage.setItem("Token", data.token);
      localStorage.setItem("SessionId", data.sessionId);

      let redirect =
        _this2.props.redirect == null ? "/" : _this2.props.redirect;
      if (data.isReset) {
        redirect = "/changePassword";        
      }

      window.location = redirect;
    }
    this.setState({ validating: false });
  };

  handleRef = elem => {
    this.state.txtUser = elem;
  };

  reCaptchaLoaded() {
    console.log("Loaded");
    this.setState({ captchaLoading: false });
  }

  componentDidMount = () => {
    this.checkBrowser();
    this.checkDBVersion();
    if (this.state.txtUser != null) this.state.txtUser.focus();
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handlePress = event => {
    if (event.key == "Enter")
      setTimeout(() => {
        this.handleLogin();
      }, 0);
  };

  checkBrowser = () => {
    let isFirefox = typeof InstallTrigger !== "undefined";
    this.setState({ isBrowserRecommended: isFirefox });
  };

  checkDBVersion = () => {
    ajaxPost({
      url: "api/Setting/getDbVersion",
      credentials: "include",
      data: {
        SessionKey: "HRIS_SessionId"
      },
      onError: () => { },
      onSuccess: data => {
        if (data !== info.dbVersion) {
          this.setState({ dbSupported: false });
          this.setState({ error: "Database not supported." });
        }
      },
      finally: () => {
        this.setState({ validating: false });
      }
    });
  };

  verifyCallback = function (response) {
    this.setState({ disableLogin: false });
  }

  resetCaptcha = () => {
    if (recaptchaInstance != null)
      recaptchaInstance.reset();
  }

  render() {
    const hoverStyle = this.state.isHovered
    ? {
        transform: 'scale(1.1)',
      }
    : {};

    return (
      <div style={styles.page}>
        {
          !isMobile &&
          <div style={styles.main2} className="w3-hide-small">
            <Grid columns={2} divided>
              <Grid.Row stretched>
                <Grid.Column style={{ border: "1px" }}>
                  <div style={{ marginTop: "50px" }} className="w3-center">
                    <img src={global.logo_url} style={styles.logo} />
                    <br />
                    <label
                      style={{
                        fontSize: "18px",
                        color: "#383838",
                        fontFamily: "Segoe UI"
                      }}
                    >
                      Time And Attendance With Payroll
                    </label>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div style={styles.section}>
                    <Input
                      name="username"
                      disabled={!this.state.dbSupported}
                      value={this.state.username}
                      placeholder="Username"
                      style={styles.textField}
                      onChange={this.handleChange.bind(this)}
                      onKeyPress={this.handlePress.bind(this)}
                      ref={this.handleRef.bind(this)}
                    />
                    <Input
                      name="password"
                      disabled={!this.state.dbSupported}
                      value={this.state.password}
                      placeholder="Password"
                      style={styles.textField}
                      type={this.state.showPassword ? "text" : "password"}
                      onChange={this.handleChange.bind(this)}
                      onKeyPress={this.handlePress.bind(this)}
                      icon={
                        <Icon
                          name={"eye"}
                          onMouseDown={() =>
                            this.setState({ showPassword: true })
                          }
                          onMouseUp={() => this.setState({ showPassword: false })}
                          link
                        />
                      }
                    />
                    <div style={styles.wrapper}>
                      {
                        this.state.showCaptcha &&
                        <div>
                          <Recaptcha ref={e => recaptchaInstance = e} render='onload' sitekey="6LdoupAdAAAAAMjrYI0XvHHNjQgx7cjHgekNcINk" onloadCallback={this.reCaptchaLoaded.bind(this)} verifyCallback={this.verifyCallback.bind(this)} />
                        </div>
                      }
                      <Button
                        disabled={
                          this.state.validating || !this.state.dbSupported || this.state.disableLogin
                        }
                        raised
                        color="primary"
                        style={styles.button}
                        onClick={this.handleLogin.bind(this)}
                      >
                        Login
                      </Button>
                      {this.state.validating && <Loader size="mini" />}
                    </div>
                    <div style={styles.message}>{this.state.error}</div>
                    <div style={{ marginRight: "0px", marginLeft: "auto" }}>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => (window.location = "/forgotPassword")}
                      >
                        {" "}
                        Forgot Password?{" "}
                      </a>
                      <br />
                      <br />
                      <p>By logging in you agree to our
                        <br />
                        <a href='https://zkteco.ph/Docs?type=Terms' target="_blank">Terms of Service</a>&nbsp;and&nbsp;
                        <a href="https://zkteco.ph/Docs?type=Privacy" target="_blank">Privacy Policy</a></p>
                    </div>
                    {/* <div style={{ marginTop: "5px" }}>
                    <label>Ver.&nbsp;{window.version}</label>
                    <br/>
                    <label>DBVer.&nbsp;{window.dbversion}</label>
                  </div> */}
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row stretched>
                { this.state.isHold && 
                  <Message color="red">
                  <Icon name="warning sign"></Icon>
                  Your account has been temporarily suspended. Kindly coordinate with our team to proceed with reactivation.
                  <a href="https://zkteco.ph/#ContactUs">Contact Us.</a> </Message>
                }
              </Grid.Row>
            </Grid>

            {/* NPC-COR SEAL */}
            <div
              style={{ ...styles.CorSeal, ...hoverStyle }}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
            >
                <img src={global.CORSeal_url} 
                  style={styles.CorLogo} 
                  onClick={this.handleClick}
                />
            </div>

            {this.state.isPopUpVisible && (
              <div>
              <Modal open size="tiny" onClose={this.handleClosePopUp}>
                    <Modal.Content>
                      <Modal.Description style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Image
                          src={global.CORSeal_url}
                          style={{ height: "600px", width: "auto" }}
                        />
                      </Modal.Description>
                    </Modal.Content>
                    {/* <Modal.Actions>
                      <Button basic content="OK" onClick={this.handleClosePopUp}></Button>
                    </Modal.Actions> */}
              </Modal>
              </div>
            )}
          </div>

        }

        {
          isMobile &&
          <div style={styles.main} className="w3-hide-large w3-hide-medium">
            <div style={styles.container}>
              <div style={styles.form}>
                <div style={styles.section}>
                  <img src={global.logo_url} style={styles.logo} />
                </div>
                <div style={styles.section} className="w3-center">
                  <label style={{ fontSize: "18px", color: "#383838" }}>
                    Time And Attendance With Payroll
                  </label>
                </div>
                <div style={styles.section}>
                  <Input
                    name="username"
                    disabled={!this.state.dbSupported}
                    value={this.state.username}
                    placeholder="Username"
                    style={styles.textField}
                    onChange={this.handleChange.bind(this)}
                    onKeyPress={this.handlePress.bind(this)}
                    ref={this.handleRef.bind(this)}
                  />
                  <Input
                    name="password"
                    disabled={!this.state.dbSupported}
                    value={this.state.password}
                    placeholder="Password"
                    style={styles.textField}
                    type={this.state.showPassword ? "text" : "password"}
                    onChange={this.handleChange.bind(this)}
                    onKeyPress={this.handlePress.bind(this)}
                    icon={
                      <Icon
                        name={"eye"}
                        onMouseDown={() => this.setState({ showPassword: true })}
                        onMouseUp={() => this.setState({ showPassword: false })}
                        link
                      />
                    }
                  />
                  <div style={styles.wrapper}>
                    {
                      this.state.showCaptcha &&
                      <Recaptcha ref={e => recaptchaInstance = e} render='onload' sitekey="6LdoupAdAAAAAMjrYI0XvHHNjQgx7cjHgekNcINk" onloadCallback={this.reCaptchaLoaded.bind(this)} />
                    }
                    <Button
                      disabled={
                        this.state.validating || !this.state.dbSupported || this.state.disableLogin
                      }
                      raised
                      color="primary"
                      style={styles.button}
                      onClick={this.handleLogin.bind(this)}
                    >
                      Login
                    </Button>
                    {this.state.validating && <Loader size="mini" />}
                  </div>
                  <div style={styles.message}>{this.state.error}</div>
                  <div>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => (window.location = "/forgotPassword")}
                    >
                      {" "}
                      Forgot Password?{" "}
                    </a>
                    <br />
                    <br />
                    <p>By logging in you agree to our
                      <br />
                      <a href='https://zkteco.ph/Docs?type=Terms' target="_blank">Terms of Service</a>&nbsp;and&nbsp;
                      <a href="https://zkteco.ph/Docs?type=Privacy" target="_blank">Privacy Policy</a></p>
                  </div>
                  {/* <div style={{ marginTop: "5px" }}>
                  <label>Ver.&nbsp;{window.version}</label>
                  <br/>
                  <label>DBVer.&nbsp;{window.dbversion}</label>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        }
        {/* {!this.state.isBrowserRecommended && (
          <div style={styles.recommendedBrowserMeesage}>
            <p>
              <label>
                This Browser is not recommended for HRIS. <br />
                Please change to Mozilla Firefox for better performance.
              </label>
              <br />
              <a
                style={{ color: "#808080", textDecoration: "underline" }}
                href="https://www.mozilla.org/en-US/firefox/download/thanks/"
                target="_blank"
              >
                Click here to download Mozilla Firefox
              </a>
            </p>
          </div>
        )} */}
      </div>
    );
  }
}


export default Login;
